<template>
  <div>
    <button class="btn btn-outline-primary" @click="exportInvoices">Export</button>
  </div>
</template>

<script>
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { db } from '@/shared/firebase'

require('moment/locale/de')

export default {
  props: {
    month: {
      type: String,
      required: true
    }
  },
  methods: {
    async exportInvoices() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Invoices')
      worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1 }]

      worksheet.columns = [
        { header: 'Umsatz', key: 'revenue', width: 10 },
        { header: 'Soll/Haben-Kennzeichen', key: 'debitCredit' },
        { header: 'WKZ Umsatz', key: 'WKZRevenue' },
        { header: 'Kurs', key: 'rate' },
        { header: 'Basis-Umsatz', key: 'basicRevenue' },
        { header: 'WKZ Basis-Umsatz', key: 'WKZBasicRevenue' },
        { header: 'Konto', key: 'account' },
        { header: 'Gegenkonto (ohne BU-Schlüssel) ', key: 'contraAccount' },
        { header: 'BU-Schlüssel', key: 'BU' },
        { header: 'Belegdatum', key: 'invoiceDate', width: 10 },
        { header: 'Belegfeld 1', key: 'invoiceField1', width: 14 },
        { header: 'Belegfeld 2', key: 'invoiceField2' },
        { header: 'Skonto', key: 'discount' },
        { header: 'Buchungstext', key: 'bookingText', width: 50 }
      ]

      const invoices = await db
        .collection('invoices')
        .where('date.invoice', '>=', moment(this.month).toDate())
        .where(
          'date.invoice',
          '<=',
          moment(this.month)
            .endOf('month')
            .toDate()
        )
        .get()
        .then(snapshot => {
          return snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
        })
      invoices.forEach(i => {
        const booking = {
          debitCredit: 'S',
          account: this.$store.state.organizations.find(o => o.id === i.organizationId).customerId,
          invoiceDate: moment(i.date.invoice.toDate()).format('DMM'),
          invoiceField1: i.id.replace('/', '_'),
          bookingText: i.address.name1
        }
        if (i.sums.gross[7] !== 0) {
          worksheet.addRow({
            ...booking,
            contraAccount: '8300',
            revenue: parseFloat(i.sums.gross[7].toFixed(2))
          })
        }
        if (i.sums.gross[19] !== 0) {
          worksheet.addRow({
            ...booking,
            contraAccount: '8400',
            revenue: parseFloat(i.sums.gross[19].toFixed(2))
          })
        }
      })
      workbook.xlsx.writeBuffer().then(data => {
        var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(blob, `Invoices ${this.month}.xlsx`)
      })
    }
  }
}
</script>
