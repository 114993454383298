<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="d-flex align-items-center flex-grow-1">
        <h1 class="mr-4">Invoices</h1>
        <MonthSelector
          from="2021-03"
          :to="new Date().toISOString().substr(0, 7)"
          :value="month"
          @change="val => (month = val)"
          class="mr-2"
        />
        <ul class="nav nav-tabs flex-grow-1">
          <li class="nav-item" v-for="filterKey in ['all', 'overdue', 'overpaid']" :key="filterKey">
            <button
              class="nav-link text-capitalize"
              :class="{ active: filter === filterKey }"
              @click.prevent="filter = filterKey"
            >
              {{ filterKey }}
            </button>
          </li>
        </ul>
        <InvoicesExport class="ml-2" :month="month" />
      </div>
    </div>
    <h3 class="h4 ml-2 mb-3">{{ month.substr(5, 2) }} / {{ month.substr(0, 4) }}</h3>
    <TableSkeleton v-if="invoiceCollections === null" :small="true" />
    <table class="table table-hover table-sm mb-5" v-else>
      <thead class="thead-light">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Name</th>
          <th scope="col">Date</th>
          <th scope="col">Due</th>
          <th scope="col">Paid</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="invoiceCollection in invoiceCollections"
          :key="invoiceCollection.id"
          :class="{
            'table-success': invoiceCollection.completelyPaid,
            'table-warning': invoiceCollection.overdue
          }"
        >
          <td>{{ invoiceCollection.id }}</td>
          <td>{{ invoiceCollection.address.name }}</td>
          <td>{{ formatDate(invoiceCollection.date.invoice.toDate()) }}</td>
          <td>{{ formatDate(invoiceCollection.date.paymentDue.toDate()) }}</td>
          <td class="text-right">
            {{ formatCurrency(invoiceCollection.paidAmount || 0) }} /
            {{ formatCurrency(invoiceCollection.sums.gross.total) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { db } from '@/shared/firebase'
import moment from 'moment'
import TableSkeleton from '@/components/TableSkeleton'
import InvoicesExport from '@/components/Invoices/InvoicesExport'
import MonthSelector from '@/components/MonthSelector'

export default {
  components: { TableSkeleton, InvoicesExport, MonthSelector },
  data() {
    return {
      filter: 'all',
      subscription: null,
      invoiceCollections: null,
      month: new Date().toISOString().substring(0, 7)
    }
  },
  created() {
    this.subscripe()
  },
  beforeDestroy() {
    this.subscription()
  },
  methods: {
    subscripe() {
      if (this.subscription) this.subscription()
      this.invoiceCollections = null
      const from = moment(this.month).toDate()
      const to = moment(this.month)
        .endOf('month')
        .toDate()
      this.subscription = db
        .collection('invoiceCollections')
        .where('date.invoice', '>=', from)
        .where('date.invoice', '<=', to)
        .orderBy('date.invoice', 'desc')
        .onSnapshot(snapshot => {
          const invoiceCollections = []
          snapshot.docs.map(doc => {
            const invoiceCollection = { ...doc.data(), id: doc.id }
            const dueDate = invoiceCollection.date.paymentDue.toDate()
            invoiceCollection.overdue = dueDate < new Date() && !invoiceCollection.completelyPaid
            invoiceCollection.monthString = moment(invoiceCollection.date.invoice.toDate()).format('MM-YYYY')
            invoiceCollections.push(invoiceCollection)
          })
          this.invoiceCollections = invoiceCollections
        })
    }
  },
  watch: {
    month() {
      this.subscripe()
    }
  }
}
</script>
