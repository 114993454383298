<template>
  <select class="custom-select w-auto" :value="value" @change="$emit('change', $event.target.value)">
    <option v-for="month in months" :key="month" :value="month">
      {{ month.substr(5, 2) }} / {{ month.substr(0, 4) }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    from: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    },
    value: {
      required: true
    }
  },
  computed: {
    months() {
      const startMonth = parseFloat(this.from.substr(5, 2))
      const endMonth = parseFloat(this.to.substr(5, 2))
      const startYear = parseFloat(this.from.substr(0, 4))
      const endYear = parseFloat(this.to.substr(0, 4))
      const months = []
      for (let year = startYear; year <= endYear; year++) {
        const maxMonth = year === endYear ? endMonth : 12
        for (let month = year === startYear ? startMonth : 1; month <= maxMonth; month++) {
          months.push(`${year}-${month.toString().padStart(2, '0')}`)
        }
      }
      return months
    }
  }
}
</script>
