<template>
  <table class="table" :class="{ 'table-sm': small }">
    <thead class="thead-light">
      <tr>
        <th><div class="table-skeleton__bar" :style="`width: ${randomWidth()}`" /></th>
        <th><div class="table-skeleton__bar" :style="`width: ${randomWidth()}`" /></th>
        <th><div class="table-skeleton__bar" :style="`width: ${randomWidth()}`" /></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="index in small ? 15 : 7" :key="index" :style="`opacity: ${1 / index}`">
        <td>
          <div class="table-skeleton__bar" :style="`width: ${randomWidth()}`" />
        </td>
        <td><div class="table-skeleton__bar" :style="`width: ${randomWidth()}`" /></td>
        <td><div class="table-skeleton__bar" :style="`width: ${randomWidth()}`" /></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    randomWidth() {
      return Math.floor(Math.random() * 10) + 10 + 'rem'
    }
  }
}
</script>

<style lang="scss" scoped>
.table-skeleton {
  &__bar {
    height: 1rem;
    margin: 0.25rem 0;
    background: #ddd;
  }
}
</style>
